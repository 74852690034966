:root {
  --tl-color: #08ca8d;
}
progress {
  border-radius: 25px;
  overflow: hidden;
}
input[type="range"].timeline {
  width: 100% !important;
  background-color: var(--tl-color);
  -webkit-appearance: none;
}
input[type="range"].timeline:focus {
  outline: none;
}
input[type="range"].timeline::-webkit-slider-runnable-track {
  background: transparent;
  border: none;
  border: 0;
  width: 100%;
  height: 2px;
  cursor: pointer;
}

input[type="range"].timeline:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type="range"].timeline::-moz-range-track {
  background: transparent;
  border: none;
  width: 100%;
  height: 2px;
  cursor: pointer;
}

input[type="range"].timeline::-webkit-slider-thumb {
  opacity: 0;
  /* display: none; */
}
input[type="range"].timeline::-moz-range-thumb {
  opacity: 0;
  /* display: none; */
}
input[type="range"].timeline::-ms-thumb {
  opacity: 0;
  /* display: none; */
}

input[type="range"].timeline::-ms-track {
  background: transparent;
  border: none;
  color: transparent;
  width: 100%;
  height: 1.2px;
  cursor: pointer;
}
input[type="range"].timeline::-ms-fill-lower {
  background: transparent;
  border: none;
}
input[type="range"].timeline::-ms-fill-upper {
  background: transparent;
  border: none;
}

input[type="range"].timeline:focus::-ms-fill-lower {
  background: transparent;
}
input[type="range"].timeline:focus::-ms-fill-upper {
  background: transparent;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
  how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"].timeline {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

.fv-timeline-comment-body.zoomed {
  box-shadow: rgba(250, 250, 250, 0.3) 0px 30px 60px -12px inset,
    rgba(255, 255, 255, 0.3) 0px 18px 36px -18px inset;
}

.fv-studio-sidebar {
  overflow: hidden;
}
