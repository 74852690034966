/*
.left_400p {left: 400% !important;}
.left_-50p {left: -50% !important;}

.left_400p + .modal {
    position: absolute;
    left: -50%;
}
*/

.cr-boundary,
.logo-preview,
.bakground-preview {
  background-color: #eaeaea;
}

.croppie-container {
  border-radius: 13px;
}

.logo-wrapper,
.background-wrapper {
  background-color: #ddd;
  padding: 0 10px 0 20px;
  border-radius: 20px;
  z-index: 100;
  overflow: hidden;
}

.logo-wrapper.full:hover,
.background-wrapper.full:hover {
  border-radius: 20px;
}

.logo-wrapper > .delete,
.background-wrapper > .delete {
  right: 30px;
  top: 5px;
}

.croppie-modal {
  max-width: 50vw !important;
  max-height: 50vh !important;
}

.logo-label > .logo-preview,
.background-label > .background-preview {
  display: none;
}

.logo-label:hover > .logo-preview,
.background-label:hover > .background-preview {
  display: block;
  position: absolute;
  border: 2px solid #ccc;
  border-radius: 1px;
  top: -150px;
  left: 150px;
  z-index: 1000;
}

.switch-theme {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 48px;
  margin-top: -5px;
}

.switch-theme input {
  display: none;
}

.slider-theme {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #111;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
  border: 2px solid;
}

.slider-theme:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 12px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  transition: 0.4s;
  border-radius: 4px;
}

input:not(:checked) + .slider-theme {
  background-color: #000;
}
input:not(:checked) + .slider-theme::before {
  background-color: #fff;
}
input:checked + .slider-theme::before {
  background-color: #111;
}

input:checked + .slider-theme {
  background-color: #fff;
}

input:focus + .slider-theme {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-theme:before {
  -webkit-transform: translateX(42.5px);
  -ms-transform: translateX(42.5px);
  transform: translateX(104px);
}

.slider-theme:after {
  color: #fff;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  width: 90px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider-theme:after {
  color: #111;
}

.slider-theme.en:after {
  content: var(--dark-text-content-en);
}
.slider-theme.fr:after {
  content: var(--dark-text-content-fr);
}
.slider-theme.de:after {
  content: var(--dark-text-content-de);
}
.slider-theme.es:after {
  content: var(--dark-text-content-es);
}
.slider-theme.pt:after {
  content: var(--dark-text-content-pt);
}

input:checked + .slider-theme.fr:after {
  content: var(--light-text-content-fr);
}
input:checked + .slider-theme.en:after {
  content: var(--light-text-content-en);
}
input:checked + .slider-theme.de:after {
  content: var(--light-text-content-de);
}
input:checked + .slider-theme.es:after {
  content: var(--light-text-content-es);
}
input:checked + .slider-theme.pt:after {
  content: var(--light-text-content-pt);
}

.input-image {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
