.pricing .card-price {
  font-size: 3rem;
  margin: 0;
  font-weight: 600 !important;
  color: #666;
  letter-spacing: -0.07em;
}

.saved-eq {
  color: #52ffb8;
}
