.input-bottom {
  justify-content: end;
  align-items: end;
  flex-direction: column;
  /* height: 100%; */
  /* align-self: end; */
  bottom: 0;
  height: 80px;
  left: 0;
}

.lh-1 {
  line-height: 1;
}

div.uploader-tooltip.mbox {
  visibility: hidden;
  display: inline-block;
  bottom: 100%;
  width: 75%;
  position: absolute;
  left: 12.5%;
}

div.uploader.empty
  > div.uploader.drop.empty.fv_uploader_empty:hover
  + div.uploader-tooltip.mbox {
  /*visibility: visible;*/
}

.triangle.dark {
  border-top-color: #212121;
}
