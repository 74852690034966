.form-control[aria-disabled="true"],
.disabled {
  opacity: 0.7;
}

.profil-infos-panel {
  color: #666;
}

.fv-profil-wrapper {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 20px;
  padding: 14px;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px;
}

.fv-apikey-input-wrapper.mobile input {
  font-size: 12px;
  padding: 0;
}
.fv-apikey-link-wrapper {
  text-align: right;
  align-self: center;
}
.fv-apikey-btn-wrapper {
  position: absolute;
  top: 10px;
  right: 0px;
  height: 38px;
}

.fv-apikey-btn-wrapper.mobile {
  position: relative;
  display: flex;
  height: 60px;
}

.fv-apikey-btn {
  margin-left: 5px;
  height: 38px;
}

.fv-apikey-btn-wrapper.mobile > .fv-apikey-btn {
  height: 50px;
}

.fv-api-modal {
  max-width: 800px;
  max-height: 80vh;
  overflow-y: scroll;
}

.fv-api-modal.mobile {
  max-height: 90vh;
}
