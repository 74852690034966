.hover-pointer:hover {
  cursor: pointer !important;
}

.odd {
  background: inherit;
}

.even {
  background: rgba(210, 210, 210, 0.15);
}

.fv-deposit-my-file {
}
.fv-deposit-my-folder {
  background: rgba(180, 220, 180, 0.25) !important;
}

.fv-filedetails-filename {
  width: 75%;
}
.fv-filedetails-fileinfos {
  width: 25%;
  justify-content: space-between;
  display: flex;
  padding-right: 5%;
}

.fv-filedetails-filename-header {
  width: 75%;
}
.fv-filedetails-fileinfos-header {
  justify-content: space-between;
  display: flex;
  width: 25%;
}

.fv-transferdetails-foldername {
  padding-left: 10px;
  padding-right: 10px;
}
.fv-transferdetails-filename {
  /*padding-left: 10px;*/
}

.fv-deposit-icon {
}
