:root {
  --dark-text-content-fr: "Texte clair";
  --light-text-content-fr: "Texte sombre";
  --dark-text-content-en: "Dark text";
  --light-text-content-en: "Light text";
  --dark-text-content-de: "Dunkler Text";
  --light-text-content-de: "Heller Text";

  --dark-text-content-es: "Texto oscuro";
  --light-text-content-es: "Heller ligero";

  --dark-text-content-pt: "Texto negro";
  --light-text-content-pt: "Texto leve";
}

html,
body,
div#root {
  overflow-x: hidden !important;
  /* overflow-y: hidden; */
}

.mobiler-header > header {
  overflow-y: visible !important;
  padding-top: 0;
  padding-bottom: 0;
}

.mobile .mobile-header .default-header {
  border-bottom: none !important;
}

.mobile > div .brand.logo {
  height: 50px;
  overflow: hidden;
}

.mobile .navbar {
  min-height: 50px;
  /*    padding-top: 50px;*/
}

.mobile > div > header button.navbar-toggler {
  position: fixed;
  right: 0px;
  top: 10px;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@media screen and (max-width: 768px) {
  input[type="checkbox"],
  input[type="radio"] {
    width: 20px;
  }
}

/*MY CUSTOM BOOTSTRAP*/

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

/* GRADIENT CUSTOMIZATION */

.color-block-5 {
  height: 30px;
  width: 120px;
}

.whitebox {
  background: white;
  padding: 10px;
  box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.24), 0 0px 0px 0 rgba(0, 0, 0, 0.19) !important;
}

.sombre {
  float: left;
}

.clair {
  float: left;
  background: #eaeaea;
}

.color-select-block {
  padding: 10px;
  border: 1px solid #eaeaea;
  height: 500px;
}

.test.color-block-5.mb-1.mx-auto.z-depth-1 {
  background: linear-gradient(90deg, #141e30 0%, #243b55 100%) !important;
}
/*THEME CLAIR */
.test {
  background: linear-gradient(90deg, #141e30 0%, #243b55 100%) !important;
}
.rouge {
  background: linear-gradient(90deg, #da4453 0%, #89216b 100%) !important;
}
.vertige {
  background: linear-gradient(90deg, #00bf8f 0%, #001510 100%) !important;
}
.sublime {
  background: linear-gradient(90deg, #fc466b 0%, #3f5efb 100%) !important;
}
.veryblue {
  background: linear-gradient(90deg, #0575e6 0%, #021b79 100%) !important;
}
.deepsea {
  background: linear-gradient(90deg, #2c3e50 0%, #4ca1af 100%) !important;
}

/*THEME SOMBRE*/
.rb {
  background: linear-gradient(90deg, #e0eafc 0%, #cfdef3 100%) !important;
}
.bb {
  background: linear-gradient(90deg, #ece9e6 0%, #ffffff 100%) !important;
}
.ppp {
  background: linear-gradient(90deg, #8e9eab 0%, #eef2f3 100%) !important;
}
.orange {
  background: linear-gradient(90deg, #ffb347 0%, #ffcc33 100%) !important;
}
.aa {
  background: linear-gradient(90deg, #de6262 0%, #ffb88c 100%) !important;
}
.am {
  background: linear-gradient(
    90deg,
    #feac5e 0%,
    #c779d0 50%,
    #4bc0c8 100%
  ) !important;
}

.bb .text-white,
.ppp .text-white,
.orange .text-white,
.aa .text-white,
.am .text-white,
.rb .text-white,
.custom-1 .text-white,
.bb-header .text-white,
.ppp-header .text-white,
.orange-header .text-white,
.aa-header .text-white,
.am-header .text-white,
.rb-header .text-white,
.custom-1-header .text-white {
  color: #333 !important;
}

.bb > .home.content,
.ppp > .home.content,
.orange > .home.content,
.aa > .home.content,
.am > .home.content,
.rb > .home.content,
.custom-1 > .home.content {
  background-color: transparent !important;
}

.default-header:not(.normal-header) {
  box-shadow: none;
}
.default-header:not(.normal-header) > nav.navbar {
  box-shadow: none
    /*0 0px 5px 0 rgba(0, 0, 0, 0.16), 0 0px 10px 0 rgba(0, 0, 0, 0.12)*/;
}

.bb .uploader.drop.empty,
.ppp .uploader.drop.empty,
.orange .uploader.drop.empty,
.aa .uploader.drop.empty,
.am .uploader.drop.empty,
.rb .uploader.drop.empty,
.custom-1 .uploader.drop.empty {
  border-color: #333 !important;
  /*
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    */
}

.mobile .default-header {
  overflow: visible !important;
  top: 0px !important;
  transition: all 0.4s;
}

.default-header:not(.normal-header):not(.open) {
  overflow: hidden !important;
  top: -60px !important;
  transition: all 0.4s;
}

.fv-header-opener,
.fv-header-opener > .arrow-header {
  transition: all 0.4s;
}

.fv-header-opener.active > .arrow-header {
  transform: rotateZ(180deg);
}

.fv-header-opener.active {
  top: 0px;
}

.fv-header-opener.inactive {
  top: 0px;
}

.default-header:not(.normal-header).open {
  top: 0px !important;
  transition: all 0.4s;
}

.default-header.normal-header {
  /*overflow: hidden !important;*/
  top: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.default-header:not(.normal-header):not(.no-hover):hover {
  top: 0px !important;
  transform: rotateZ(0deg) !important;
  transition: all 0.4s;
}

.default-header:not(.normal-header):not(.no-hover):hover .arrow-header {
  overflow: hidden !important;
  top: 0px !important;
  transform: rotateZ(-180deg) !important;
  transition: all 0.4s;
}

/*transform: rotateX(180deg) !important;*/

*:focus,
*:focus-within,
*:visited {
  outline: none !important;
}

.message-text-rotator {
  font-weight: bold;
  font-size: 28px;
}

.o-y-scroll {
  overflow-y: scroll;
}

/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hvr-hang {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-hang:hover,
.hvr-hang:focus,
.hvr-hang:active,
.hvr-hang.active {
  -webkit-animation-name: hvr-hang-sink, hvr-hang;
  animation-name: hvr-hang-sink, hvr-hang;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

.hover-text:hover {
  cursor: text !important;
}

.mobile footer > div.container {
  margin-top: 10px;
}

.mobile .default-header {
  overflow: visible !important;
  top: 0px !important;
  transition: all 0.4s;
}

body.ReactModal__Body--open {
  height: 100vh !important;
  overflow: hidden !important;
}

.home.container-fluid,
.download.container-fluid {
  background: no-repeat center center/cover;
}

#root.mobile {
  width: 100vw;
  overflow-x: hidden;
}

#root.mobile h3 {
  word-break: break-word;
}

.main-loader-wrapper {
  background: #eaeaea;
  color: #666;
}

.form-check-input {
  width: 15px !important;
}

.pr-20 {
  padding-right: 20px;
}

#main {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.max-w-400 {
  max-width: 400px !important;
}

@media screen and (max-width: 400px) {
  .min-font {
    font-size: 90% !important;
  }
}

/*.spinning {*/
/*    -webkit-animation-name: spin;*/
/*    -webkit-animation-duration: 4000ms;*/
/*    -webkit-animation-iteration-count: infinite;*/
/*    -webkit-animation-timing-function: linear;*/

/*    -moz-animation-name: spin;*/
/*    -moz-animation-duration: 4000ms;*/
/*    -moz-animation-iteration-count: infinite;*/
/*    -moz-animation-timing-function: linear;*/

/*    -ms-animation-name: spin;*/
/*    -ms-animation-duration: 4000ms;*/
/*    -ms-animation-iteration-count: infinite;*/
/*    -ms-animation-timing-function: linear;*/

/*    animation-name: spin;*/
/*    animation-duration: 4000ms;*/
/*    animation-iteration-count: infinite;*/
/*    animation-timing-function: linear;*/

/*}*/

/*@-ms-keyframes spin {*/
/*    from {*/
/*        -ms-transform: rotate(-90deg);*/
/*    } to {*/
/*          -ms-transform: rotate(270deg);*/
/*      }*/
/*}*/
/*@-moz-keyframes spin {*/
/*    from {*/
/*        -moz-transform: rotate(-90deg);*/
/*    } to {*/
/*          -moz-transform: rotate(270deg);*/
/*      }*/
/*}*/
/*@-webkit-keyframes spin {*/
/*    from {*/
/*        -webkit-transform: rotate(-90deg);*/
/*    } to {*/
/*          -webkit-transform: rotate(270deg);*/
/*      }*/
/*}*/
/*@keyframes spin {*/
/*    from {*/
/*        transform: rotate(-90deg);*/
/*    } to {*/
/*          transform: rotate(270deg);*/
/*      }*/
/*}*/

.custom-control-input:checked ~ .custom-control-label::before {
  background: rgb(65, 148, 136) !important;
}

.font-weight-very-important {
  font-weight: 1000 !important;
  font-size: 40px;
  letter-spacing: 0px;
}

.font-weight-important {
  font-weight: 1000 !important;
  font-size: 20px;
  letter-spacing: 0px;
  color: #666;
}

input[type="range"] {
  display: block;
  -webkit-appearance: none;
  background-color: #dddddd;
  width: 200px;
  height: 10px;
  border-radius: 5px;
  margin: 0 auto;
  outline: none !important;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #009688;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-top: -10px;
  outline: none !important;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-color: white;
  border: 2px solid #009688;
  outline: none !important;
}

input[type="range"]::-webkit-slider-thumb:active {
  transform: scale(1.6);
  outline: none !important;
}

.fv-bg-different.more {
  background: rgba(100, 255, 100, 0.1);
}

.fv-bg-different.less {
  background: rgba(255, 100, 100, 0.1);
}

.modal-unstyled {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
}

.scrolldown-wrapper {
  left: 50%;
  position: absolute;
  text-align: center;
  bottom: 0;
  transform: translate(-50%, -50%);
}

.scrolldown {
  border: 2px solid #ffffff;
  border-radius: 22.5px;
  height: 34.5px;
  margin: 0 auto 8px;
  text-align: center;
  width: 22.5px;
}

.scrolldown-p1,
.scrolldown-p2 {
  animation-duration: 1.5s;
  animation-name: scrolldown;
  animation-iteration-count: infinite;
  fill: #ffffff;
}

.scrolldown-p2 {
  animation-delay: 0.75s;
}

@keyframes scrolldown {
  0% {
    opacity: 0;
    transform: translate(0, -8px);
  }
  50% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 8px);
  }
}

.fv-custom-label > .fv-offer-tooltip {
  position: absolute;
  display: none;
  background: #000;
  border-radius: 5px;
  padding: 5px;
  z-index: 1;
  bottom: 100%;
  overflow: visible;
  width: 200px;
  left: 0;
}

.fv-custom-label:hover > .fv-offer-tooltip {
  display: block;
}

.fv-multi-email > div {
  max-height: 25vh;
  overflow: scroll;
}

.hover-time-info::after {
  content: "";
  position: absolute;
  left: 41%;
  top: 100%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #e8e8e8;
  clear: both;
}

html,
body,
#root {
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden !important;
}
