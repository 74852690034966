.cloudy-gradient {
  background-image: linear-gradient(
    120deg,
    #fdfbfb 0%,
    #ebedee 100%
  ) !important;
}

.cta-button {
  text-transform: uppercase !important;
  font-size: 80% !important;
  border-radius: 5rem !important;
  letter-spacing: 0.1rem !important;
  font-weight: bold !important;
  padding: 1rem !important;
  opacity: 0.7 !important;
  transition: all 0.2s !important;
  color: #fff !important;
  background-color: #4285f4 !important;
}

.btn:hover,
.btn:active,
.btn:focus {
  outline: 0 !important;
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.band-title {
  font-size: 24px;
  font-weight: 400;
  color: #222;
  line-height: 1.2em !important;
}
