.uploader.drop.empty {
  border: 2px #fff dashed;
  border-radius: 10px;
  display: inline-block;
  background: transparent;
  max-height: 270px;
  overflow: hidden;
}

.uploader.drop.full {
  overflow-y: scroll;
  padding: 10px 30px 25px;
  background: #fff;
  border-radius: 1.25rem;
  /*min-height: 60vh !important;*/
  max-height: calc(100vh - 250px);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
  z-index: 10000;
}

.uploader.drop.full::-webkit-scrollbar {
  display: none;
}

.uploader.action-container:hover:not(.mobile),
div[data-status="active"] {
  background-color: rgba(255, 255, 255, 0.2) !important;
  cursor: pointer;
}

.uploader.action-container.loading:hover,
div[data-status="active"].loading {
  background-color: rgba(222, 222, 222, 1) !important;
  cursor: initial;
}

.uploader.input {
  position: relative;
}

.uploader.action-container.empty {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  text-align: center;
  color: #fff;
}

.uploader.action-container.full {
  z-index: 1000;
  position: relative;
  display: inline-block;
  text-align: center;
}

.uploader.action.main:not(.full) {
  font-weight: bold;
  font-size: 22px;
  margin: 20px 0;
}

.uploader.action.alt {
  display: block;
  font-size: 16px;
  font-weight: normal;
  margin: 20px;
}

.uploader.right.side {
  color: #fff;
  display: inline-flex;
  padding: 0 5%;
  vertical-align: middle;

  position: absolute;
  top: 40vh;
}

.uploader.message {
  font-weight: bold;
  font-size: 45px;
}

.preview.container {
  display: flex;
  color: #fff;
  font-size: 16px;
  margin: 5px 0;
}

.preview.label {
  line-height: 30px;
  margin: 0 10px;
}

.preview.file.info {
  display: inline-flex;
  height: 30px;
  width: 340px;
  margin-right: 10px;
  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
    to bottom left,
    rgba(0, 100, 130, 0.7),
    rgba(0, 10, 80, 0.7)
  );
  background-image: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.7)
  );
  overflow: scroll;
}

.preview.file.action {
  display: inline-flex;
  height: 30px;
  width: 50px;
}

.preview.file.action > button {
  color: #000;
  text-align: center;
  border: none;
  border-radius: 30px;
  width: 30px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.1);
}

.preview.file.action > button:hover {
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.preview.bg.success {
  position: absolute;
  height: 30px !important;
  z-index: 1;
  height: inherit;
  border-radius: 15px;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(
    to right top,
    rgba(0, 100, 130, 0.7),
    rgba(0, 10, 80, 0.7)
  );
  transition: all 0.5s;
}

.uploader.action.main.full {
  color: #444;
}

.info.error {
  color: red;
}

.uploader-modal {
  position: absolute !important;
  /* padding: 30px; */
  /*overflow-y: scroll;*/
  z-index: 100;
  /* max-width: 30% !important; */
  /* max-height: calc(100vh - 150px); */
  top: 20%;
  left: 10%;
  /*min-height: 60%;*/
  width: 25%;
  min-width: 300px;
  padding: 30px;
  overflow-y: scroll;
  background: #f9f9ff;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.modal-left {
  /*position: absolute !important;*/
  padding: 30px;
  overflow-y: scroll;
  background: #f9f9ff;
  z-index: 100;
  max-height: calc(100vh - 150px);
  min-width: 41.666667%;
  /*top: 20%;
    left: 10%;*/
  height: 60%;
}

.modal-right {
  /*position: absolute !important;*/
  padding: 30px;
  overflow-y: scroll;
  background: #f9f9ff;
  z-index: 100;
  max-height: calc(100vh - 150px);
  height: 60%;
}

.uploader-overlay {
  height: 100%;
  width: 100%;
}

.btn-rounded {
  border-radius: 18em !important;
}

.my-checkbox {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.tempting-azure-gradient {
  background-image: linear-gradient(40deg, #2096ff, #05ffa3) !important;
}

.preview.container {
  margin: 10px 0;
  /* background: #F2F2F2; */
  border: 0.1px solid rgba(150, 150, 150, 0.4);
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.wrapper-info {
  font-size: 25px;
  position: absolute;
  left: 0;
  top: 5px;
}

.wrapper-info > i {
  font-style: italic;
  font-size: 20px;
  border: 1px solid #2f2f2f2f;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 30px;
}

.hover-info:hover {
  background: #fff;
  border: 1px solid rgba(150, 150, 150, 0.1);
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-out;
}

.hover-info .data-info {
  visibility: hidden;
  opacity: 0 !important;
  background-color: transparent !important;
  color: transparent !important;

  transition: color 0.25s linear, background-color 0.25s linear;
}

.hover-info:hover + .data-info {
  visibility: visible;
  position: absolute;
  font-size: 16px;
  display: table;
  opacity: 1;
  background-color: #fff !important;
  width: inherit;
  min-width: 370px;
  height: 100%;
  min-height: 50px;
  color: #222 !important;
  opacity: 1 !important;
  top: -50%;
  left: 10px;
  border: 1px solid #ccc;
  z-index: 10000;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.5);
  padding: 5px 10px !important;
  transition: color 0.25s linear, height 0.25s linear, width 0.25s linear,
    background-color 0.25s linear;
}

.hover-remove .data-hover {
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s linear;
}

.hover-remove:hover .data-hover {
  visibility: visible;
  opacity: 1;
  transition: all 0.15s linear;
}

.mobile-uploader .uploader.drop {
  height: calc(100vh - 50px);
  width: 100vw;
  position: fixed;
  top: 50px;
  left: 0;
  max-height: none !important;
  padding: 25% 0;
  transition: height 0.4s ease;
}

.mobile-uploader .uploader.drop.uploading {
  /*height: calc(100vh - 50px - 85px - 110px);*/
  width: 100vw;
  position: fixed;
  top: 50px;
  left: 0;
  max-height: none !important;
  padding: 25% 0;
  transition: height 0.4s ease;
}

.mobile-uploader .uploader.drop.full {
  padding: 15px;
}

.mobile-uploader .preview-wrapper.show {
  padding: 10px;
  width: 100%;
  z-index: 10000;
  top: 50px;
  position: fixed;
  height: calc(100vh - 50px);
  max-height: none;
  border-radius: 0;
  transition: all 0s;
  margin: auto;
  overflow-x: hidden;
}

.mobile-uploader .preview-wrapper.show .hover-remove i {
  opacity: 1;
  visibility: visible;
}

.network-container {
  z-index: 100000;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}

.react-tabs__tab--disabled:hover {
  cursor: not-allowed !important;
}

.uploader.drop.full {
  z-index: 1;
}

.fv-btn-green,
.fv-btn-green:hover,
.fv-btn-green:active,
.fv-btn-green:focus,
.fv-btn-green:visited {
  color: #0abd87 !important;
  border-color: #0abd87 !important;
}
