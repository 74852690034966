.fv-landing-card-container > div {
  gap: 5em;
  margin: 40px;
  padding: 0 50px;
  min-height: 250px;
}

.fv-landing-card-container > div > div {
  gap: 2em;
  padding: 40px 20px;
}

.fv-landing-card-container p {
  color: #aaa;
}

.fv-landing-bg-linear {
  background-color: #222;
  background-image: linear-gradient(220deg, #020811 -20%, #041622 45%);
}

@media screen and (max-width: 480px) {
  .fv-landing-card-container > div {
    flex-direction: column;
    gap: 1em;
  }
}
