.hidden {
  visibility: hidden;
}

.Collapsible__trigger {
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 10px;
  display: block;
  color: #44f;
}

.Collapsible__trigger:before {
  display: inline-block;
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.Collapsible__trigger.is-closed:before {
  content: "+" !important;
}

.Collapsible__trigger.is-open:before {
  content: "-" !important;
}

.locked {
  z-index: -1 !important;
}

.password-overlay {
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.password-modal {
  margin-top: 20vh;
  border-radius: 2px;
  padding: 30px;
  overflow-y: scroll;
  background: #f9f9ff;
  z-index: 100;
  max-width: 500px;
  max-height: 400px;
}

.btn-password {
  top: -15px;
  right: -15px;
}

.p-bottom {
  bottom: 0 !important;
  top: auto !important;
}

.p-right {
  right: 0 !important;
  left: auto !important;
}

.dezoom {
  zoom: 0.75;
}

.full-screen-input {
  /*    min-height: 50vh;
    min-width: 50vw;*/
}

.cus-label {
  line-height: 45px;
}

.recipients-invalid {
  border: 1px solid red !important;
}

.recipients-valid {
  border: 1px solid green !important;
}

.fv_expand_icon {
  color: #666;
}

.fv_icon_recipients {
  text-align: center;
  vertical-align: center;
  justify-content: center;
  align-content: center;
  height: 54px;
  border: 1px solid #ccc;
  padding-top: 9px;
}

.icon_auto_send {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url(../../../../assets/images/mail.svg);
}

.icon_auto_send_green {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url(../../../../assets/images/mail-green.svg);
}

.no-border-hover > div > div:hover:not(:focus):not(:focus-within) > fieldset {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.no-border-hover > div > div:focus-within > fieldset,
.no-border-hover > div > div:focus > fieldset {
  border-color: #4285f4 !important;
  border-width: 1.6px;
}

.no-border-hover > .MuiFormLabel-focused-34 {
  color: #4285f4 !important;
  font-weight: bold;
}

.form-control.form-check-input:focus-within,
.form-control.form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.react-tabs__tab--disabled:hover {
  cursor: not-allowed !important;
}
