.consuptionItem.container {
  display: block;
}

.consuptionItem.img,
.consuptionItem.value,
.consuptionItem.separator,
.consuptionItem.label {
  color: rgba(255, 255, 255, 0.8);
  display: block;
  text-align: center;
  font-size: 25px;
}

.consuptionItem.img {
}

.consuptionItem.value {
}

.consuptionItem.separator {
}

.consuptionItem.label {
}
