.logo-btn,
.background-btn {
  padding: 0px 10px;
  border-radius: 5px;
  /*height: 30px;*/
  /*width: 100px;*/
  /*background: #EFEFEF;*/
  /*border-radius: 5px;*/
}

.logo-input,
.background-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  height: 40px;
}

.edit-email {
  position: absolute !important;
  right: 5px;
}
