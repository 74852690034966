.login.button {
  font-size: 16px;
  color: #00c851;
  margin: 30px;
  padding: 10px;
  border: 1px solid #00c851;
  background-color: transparent;
  position: absolute;
  right: 20%;
}

.header-login {
  position: absolute;
  top: -20px;
}

.header-login-row {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
