.preview-wrapper {
  background: #fff;
  height: auto;
  overflow-y: scroll;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  border-radius: 0 1.25rem 1.25rem 0;
  padding-left: 40px;
  margin-left: -30px;
  border-left: 5px transparent;
  max-height: calc(100vh - 250px);
}

.preview-wrapper.hide {
  left: -300px;
  opacity: 0.5;
  visibility: hidden;
  transition: left 0.2s 0.5s linear, opacity 0.7s ease-in,
    visibility 0.7s linear;
}

.preview-wrapper.show {
  left: 0;
  height: auto;
  transition: left 0.2s linear, opacity 0.5s ease-in;
}

.btn-files {
  margin-left: 20px;
  margin-right: -20px;
  margin-top: 0px;
}

.header-files {
  height: 50px;
  line-height: 50px;
}

.header-transfer {
  height: 60px;
  line-height: 50px;
}

.top-50 {
  top: 50%;
}

.bottom-10 {
  bottom: 10%;
}

.left-0 {
  left: 0;
}

.data-off {
  position: fixed;
  top: -100%;
  left: -100%;
}

.icon-chevron-right,
.icon-chevron-right.hide {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url(../../../../assets/images/icon-chevron-right.svg);
  transform: rotateZ(0deg);
  transition: all 0.4s;
}

.icon-chevron-right.show {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-image: url(../../../../assets/images/icon-chevron-right.svg);
  transform: rotateZ(90deg);
  transition: all 0.4s;
}

.fv_layout_current_header_separator {
  height: 40px;
  border: 1px solid #666;
}

.layout-container {
  max-width: 480px;
}

.layout-container > div.row.w-200 {
  width: 200% !important;
}

.uploader.action-container.full {
  height: 35px;
}

.mobile-uploader .header.finished h4 a {
  font-size: 18px;
  text-align: right;
  width: 100%;
  float: right;
  margin: auto;
  line-height: 18px;
}

.mobile-uploader .preview.hover-info,
.mobile-uploader .preview.hover-info * {
  padding-right: 0;
}

.css-disabled {
  opacity: 0.7;
}
