.activityItem.container {
  display: block;
}

.activityItem.img,
.activityItem.value,
.activityItem.separator,
.activityItem.label {
  color: rgba(0, 0, 0, 0.8);
  display: block;
  text-align: center;
  font-size: 25px;
}

.activityItem.img {
}

.activityItem.value {
}

.activityItem.separator {
}

.activityItem.label {
}

.bg-light-grey {
  background: #fafafa !important;
}

.custom-progress-wrapper {
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 10px;
  height: 10px;
}
.custom-progress-value {
  height: 10px;
  background: blue;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 10px;
}
.custom-progress-value.upload-usage {
  background: red;
}
.custom-progress-value.upload-usage-free {
  background: white;
}

.custom-progress-value.upload-usage-consumed {
  background: green;
}

.upload-consumed-wrapper {
  background: #efefef;
}
