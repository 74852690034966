.footer.container {
  display: flex;
  height: 150px;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 10%;
  width: 80%;
}

footer.teal {
  background-color: #009688;
}

/*footer.text-white:not(.contact-modal) *:not(.contact-modal) {
    color: #FFF !important;
}*/

footer.text-white a {
  font-weight: normal !important;
}

.footer-fade {
  transition: margin-bottom 0.3s ease-out, opacity 0.2s linear;
}

.footer-fade.hide {
  opacity: 0.3;
  margin-bottom: -150px;
}

.footer-fade.show {
  opacity: 1;
  margin-bottom: 10px;
}

.mobile .consuptionItem.label {
  font-size: 15px;
}

.page-footer.mobile {
  bottom: 40px;
}

.page-footer.dl-fade.mobile {
  bottom: -10px;
}

.page-footer.mobile.android {
  bottom: -10px;
}

.page-footer.dl-fade.mobile.android {
  bottom: -60px;
}

.footer-fade.hide {
  margin-top: -40px;
}

.fv-version {
  font-size: 8px;
  text-orientation: mixed;
  writing-mode: vertical-lr;
  position: fixed;
  bottom: 8px;
  right: 5px;
  transform: rotateZ(-180deg);
}

.mobile > .fv-version {
  bottom: 5px;
  right: 0px;
}

.footer-preview-item {
  padding: 10px;
}

.conso-explained > .backdrop {
  opacity: 0;
  transition: height 0.2s ease-out, opacity 1s ease-out;
  left: 0;
  bottom: -60px;
  width: 100%;
  height: calc(100% + 40px);
  background: #000;
  display: block;
}

.conso-explained.hide > .backdrop {
  opacity: 0;
  bottom: -60px;
}
.conso-explained.show > .backdrop {
  opacity: 1;
  bottom: 0;
}
