/*
.register-form {
    max-width: 500px !important;
}
*/

/*textarea, input:not([type=checkbox]) {
    padding: 5px;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    width: 100%;
}*/

/*input[type=postal-code] {
    max-width: 80px !important;
}*/

label span {
  font-size: 18px;
  color: #6f6f6f;
}

.cus-adr {
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
}

.m-inside {
  margin: 15px auto;
}

.m-inside .md-form {
  margin-top: 0rem;
  margin-bottom: 0.6rem;
}

.form-check-label {
  line-height: calc(1.5em + 0.75rem + 2px);
  height: calc(1.5em + 0.75rem + 2px);
  margin-top: 0.3rem;
}
