.fv-input-action-wrapper {
  height: 40px;
  background: #fff;
  border-radius: 3px;
  padding: 1px;
}
.fv-input-action {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: rgba(117, 117, 117, 0.9);
  border-color: rgba(117, 117, 117, 0.3);
  height: 38px;
  box-shadow: none;
  outline: none;
  border-style: solid;
  z-index: 1;
  text-overflow: ellipsis;
  background: #0ab885;
  color: #ffffff;
  padding: 9px;
  /* margin: 0; */
  /* border-right: 2px solid #0ab885; */
  font-size: 0.8em;
  opacity: 1;
  font-weight: 500;
  margin: 0;
  border: 0;
  border-radius: 4px;
  width: 100%;
}

.fv-input-action-label {
  z-index: 99999;
  margin: 0;
}

.fv-input-action-label > i {
  background: #fff;
  font-size: 20px;
  color: #0ab885;
  padding: 10px !important;
  margin-right: 0 !important;
  box-shadow: 2px 2px;
  border-radius: 5px;
  margin-top: 0;
  height: 38px;
}

.fv-input-action-label > i:hover {
  cursor: pointer;
}
