.fv-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  z-index: 10;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.fv-navbar {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.fv-navbar-item ul,
fv-navbar-item li,
.fv-navbar-item a {
  margin: auto;
  height: 60px;
  line-height: 60px;
  color: #fff;
}

.small-text {
  font-size: 0.75em;
}

.zi100 {
  z-index: 100;
}
