.fv-burger-menu-wrapper {
  position: fixed;
  height: 100%;
  width: 200px;
  right: -200px;
  top: 0;
  opacity: 0;
  background-color: black;
  color: #fff;
  padding-top: 70px;
  transition: right 0.5s, opacity 0.5s;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.fv-burger-menu-wrapper.opened {
  opacity: 1;
  right: 0;
  transition: right 0.5s, opacity 1s;
}

.fv-burger-menu-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.fv-burger-menu-backdrop.close {
  visibility: hidden;
  transition: all 0.4s;
}
.fv-burger-menu-backdrop.open {
  visibility: visible;
  transition: all 0.4s;
  background: rgba(0, 0, 0, 0.15);
}
