.nb-big-green {
  font-weight: 600;
  font-size: 36px;
  color: #4caf50;
}

.w-50 {
  width: 50%;
}

.float.left {
  float: left;
}

.float.right {
  float: right;
}

h3.reason.title {
  font-weight: 600;
  font-size: 24px;
  color: #4f4f4f;
}

p.reason.text {
  width: 85%;
  margin: 20px 0;
  color: #666;
}

.reason.wrapper {
  padding: 0 20px 0 100px;
}

div.reason.container {
  margin: 20px 0;
}

div.manifest.wrapper {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/*div.manifest.wrapper.show {
    padding: 10px 30px 30px 30px;
}*/

div.manifest.wrapper,
div.manifest.wrapper *:not(h3):not(h4):not(h5) {
  list-style: inside;
  font-size: 18px;
  font-weight: normal;
  color: #666;
  text-align: left;
  line-height: 30px;
}

div.manifest.wrapper li {
  font-weight: bold !important;
}

div.manifest.wrapper ul.normal li {
  font-weight: normal !important;
}

.manifest.title {
  margin: 20px 0;
  line-height: 35px;
  font-size: 28px;
  font-weight: 600;
  color: #666;
}

.manifest.link {
  text-decoration: none;
  font-weight: 600;
  color: #2196f3;
  display: inline-block;
  margin-right: 30px;
}
