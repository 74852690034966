.fv-fullsize-element {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.fv-form-file-upload {
}

.fv-form-file-upload-label {
  cursor: pointer;
}

.fv-uploader-standalone-wrapper {
  position: relative;
  background-color: #141414;
}

.fv-uploader-standalone-wrapper.full {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  justify-content: center;
  padding: 25px;
  border-radius: 10px;
  background: white;
  /* padding: 0 0 88px 0; */
}

.fv-uploader-standalone-wrapper.empty.fv-drag-active,
.fv-uploader-standalone-wrapper.empty:hover,
.fv-uploader-standalone-wrapper.empty:active {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  border: 3px solid rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  overflow: hidden;
}
