.bg-aqua {
  background-image: linear-gradient(
    40deg,
    rgba(32, 150, 255, 0.8),
    rgba(5, 255, 163, 0.8)
  ) !important;
}

/*.section-gap {*/
/*    padding: 88px 0 !important;*/
/*}*/

.offer-header-item {
  border-radius: 5px;
}

.offer-header-item.active {
  background: rgb(240, 230, 154);
}

.offer-header-period-cta.active {
  background: #2d3e50;
}
.offer-header-period-cta {
  border-radius: 20px;
  display: inline-flex;
  max-width: 200px !important;
}

.period-giving {
  color: #00c851;
  padding: 0 10px;
  font-weight: bold;
  text-shadow: 0 1px 3px #2d3e50;
}

.numbre.text-white {
  font-size: 2.3em;
  line-height: 1em;
  position: relative;
  /*margin-top: -160px;*/
}
.t1 {
  font-weight: bold;
  font-size: 1.4em;
}

span.t2 {
  font-size: 0.8em;
}

span.kg {
  font-size: 0.4em;
}
