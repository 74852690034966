.spinner-wrapper {
}

.spinner-circle {
  transform: rotate(-90deg);
}

.spinner-bg {
  stroke-dasharray: 502;
  stroke-dashoffset: 1000;
  stroke-width: 10;
  stroke: rgb(232, 235, 237);
}

.spinner-fg {
  stroke-dasharray: 1011;
  stroke-width: 10;
}

.spinner-label {
  color: #17181a;
  -webkit-font-feature-settings: "lnum";
  font-size: 3em;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  display: inline;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  height: 100%;
  padding-top: 1.25em;
}
.spinner-label.mobile {
  font-size: 2.5em;
  padding-top: 1.15em;
}
.root-loader span {
  display: inline-block;
  height: 9px;
  width: 9px;
  border-radius: 4.5px;
  background-color: #666;
  margin: 2px;
}
@keyframes blink {
  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: 0.2;
  }
  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }
  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: 0.2;
  }
}
.root-loader span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}
.root-loader span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.2s;
}
.root-loader span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: 0.4s;
}
